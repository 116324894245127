<template>
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form
            name="sDash_validation-form"
            ref="formRef"
            :model="{ user_image }"
            :layout="formState.layout"
          >
              <div :class="`p-0 ${user_image.length ? ''  : 'image-button'}`">
                <a-form-item ref="image " name="image" class="title-normal">
                  <a-upload
                    name="image"
                    accept=".png, .jpg, .jepg"
                    :multiple="false"
                    :fileList="user_image"
                    :remove="deleteFile"
                    :beforeUpload="chooseFile"
                    list-type="picture"
                  >
                    <button class="btn-add-outlined button-content" v-if="!user_image.length">
                      <sdFeatherIcons type="image" size="20" />
                      <span class="title-normal pl-2"> Cargar imagen </span>
                    </button>
                  </a-upload>
                </a-form-item>
              </div>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
</template>

<script>
import { ref, reactive } from 'vue';
import { Notification } from 'ant-design-vue';
import { FormValidationWrap, VerticalFormStyleWrap } from '../Style';

const FileContainer = {
  name: 'FileContainer',
  components: { VerticalFormStyleWrap, FormValidationWrap },
  methods: {},
  props: {
    imageSelected: Number,
    user_image: Array,
    sendForm: null,
    eventForm: Object,
    error: Boolean
  },
  watch: {
    sendForm: function() {
      this.submit();
    },
  },
  emits: ['getFormValues'],
  async setup(props, { emit }) {
    const formRef = ref();
    const maxWeight = 20971520; //20 megabytes

    const formState = reactive({
      id: props.index,
      layout: 'vertical',
    });

    console.log(props);

    const handleError = message => {
      Notification['error']({
        message: 'Error',
        description: message,
      });
    };

    const chooseFile = (file) => {
      const auxWeight = file.size <= maxWeight;
      console.log(props.user_image);

      if (auxWeight) {
        formRef.value.clearValidate();
        //   props.eventForm.setFields({
        //     files: {
        //       errors: [],
        //     },
        // });
        props.user_image.push(file);
      } else {
        handleError(`El documento ${file.name} excede el peso permitido en la carga de archivos (20Mb).`);
      }
      return false;
    };

    const deleteFile = file => {
      const index = props.user_image.indexOf(file);
      props.user_image.splice(index, 1);
    };

    const submit = () => {
      emit('getFormValues', { is_ok: !!props.user_image.length, typeForm: 'user_image' });
    };

    return { formRef, formState, chooseFile, deleteFile, handleError, submit };
  },
};

export default FileContainer;
</script>

<style lang="sass">
.form-font
  font-family: Nutmeg
  &.helper-text
    color: #636668
  &.title
    font-weight: bold
    padding: 0

.btn-fill-add
  border: 1px solid #e70e4c
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #e70e4c
  border-radius: 7px
button:hover
  cursor: pointer

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 7px
  margin-left: 2rem

.btn-add-outlined
  background-color: white
  border: 2px solid #e70e4c
  border-radius: 20px
  color: #e70e4c
  padding: .5rem 1.5rem

.btn-add-outlined.disabled
  border: 2px solid #CCCCCC
  color: #CCCCCC
.card
  background-color: #fff !important
  padding: 1rem
  border-top: 0.5px solid #bdbdbd

.image-button
  display: flex
  justify-content: flex-end

.button-content
  display: flex !important
  align-items: center
</style>
